@import 'app.scss';
.wrapper {
  position: fixed;
  inset: 0;
  z-index: 5;
}

.backdrop {
  position: absolute;
  inset: 0;
  transition: opacity 0.5s;
  opacity: 0.5;
  background-color: #000; // differs from --color-black
}

.paper {
  position: absolute;
  left: 0;
  z-index: 1;
  background-color: var(--color-white);
  width: 100%;
  height: 50%;

  &.top {
    top: 0;
  }

  &.bottom {
    bottom: 0;
  }
}

.line {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  background-color: var(--color-black);
  width: 0;
  height: 1px;
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
  text-align: center;
  color: var(--color-black);
  font-family: var(--font-family-heading);
  font-size: clamp(1.25rem, 2.5vw, 2rem);
  font-weight: 500;

  span {
    display: inline-block; // enables transform animation
    opacity: 0;
  }
}
