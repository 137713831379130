@import 'app.scss';
.introduction {
  margin-top: spacing(26);
  margin-bottom: spacing(16);

  @include from-breakpoint(md) {
    margin-top: spacing(30);
    margin-bottom: spacing(12);
  }
}

.form {
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;

  @include from-breakpoint(lg) {
    grid-column: 3/10;
  }
}

.explanation {
  margin-top: spacing(2);
  margin-bottom: 0;
  font-style: italic;
}

.fieldset:not(:first-of-type) {
  margin-top: spacing(20);

  @include from-breakpoint(md) {
    margin-top: spacing(26);
  }
}

.toggleMotivation {
  display: flex;
  gap: spacing(2);
  align-items: center;
  margin-top: spacing(6);
  text-align: left;
  text-decoration: underline;
  color: var(--color-bg-primary-hover);
}

.toggleIcon {
  margin-top: spacing(-1); // align with text
}

.submit {
  margin-top: spacing(12);

  @include from-breakpoint(md) {
    align-self: flex-end;
  }
}
