@import 'app.scss';
.section {
  margin-top: calc(-1 * var(--stories-overlap));
  background-color: var(--background-color);
  width: 100%;
  color: var(--color-green-700);

  &.backgroundComplete {
    background: transparent !important;
  }

  &.macBook {
    background-color: var(--macbook-background-color);
  }
}

.container {
  display: grid;
  position: relative;
  gap: spacing(40);
  align-content: center;
  padding-top: spacing(60);
  min-height: 100vh;

  @include from-breakpoint(lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: spacing(13);
  }
}

.story {
  position: relative;
  line-height: 1.3;
  white-space: pre-line;
  font-size: 1.75rem;
  font-weight: 500;

  @include from-breakpoint(md) {
    font-size: 2rem;
  }

  @include from-breakpoint(lg) {
    grid-column: 2;
    padding-bottom: 50vh;
  }
}

.textContainer {
  position: relative;
  margin-top: -375px;

  &::before {
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: var(--background-color);
    width: 100%;
    height: 375px;
    content: '';
    mask-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgb(255 255 255 / 1)),
      to(rgb(255 255 255 / 0))
    );

    @include from-breakpoint(lg) {
      display: none;
    }
  }

  .macBook &::before {
    background-color: var(--macbook-background-color);
  }

  @include from-breakpoint(lg) {
    margin-top: 0;
  }
}

.paragraph {
  &:not(:last-child) {
    margin-bottom: 1em;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.word {
  display: inline-block;
  margin-right: 0.3em;
}

.audioContainer {
  transform: translateY(100%);
  opacity: 0;
  font-family: var(--font-family-base);

  @include from-breakpoint(lg) {
    position: fixed;
    bottom: 0;
    left: var(--horizontal-padding);
    z-index: 1;
    padding-bottom: spacing(4);
    overflow: hidden;
  }
}

.label {
  font-weight: 600;
}

.sub {
  font-weight: 400;
}

.circles {
  display: flex;
  margin-top: spacing(3);

  /* TEMP solution: https://goodnewsnl.atlassian.net/browse/ANCHR-330 */
  &::before,
  &::after {
    display: block;
    content: '';
  }
}

.authorImageWrapper,
.playButton {
  border-radius: 50%;
  background-color: var(--color-bg-quinary-cta);
  width: 127px;
  height: 127px;
}

.authorImageWrapper {
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    bottom: -40%;
    left: -30%;
    transform: rotate(-130deg);
    opacity: 0.3;
    z-index: 1;
    border-radius: 50%;
    background: linear-gradient(
      #c2dd67 45%,
      var(--color-image-gradient-green) 80%
    );
    width: 100%;
    height: 100%;
    content: '';
    filter: blur(1.5rem);
  }

  &::before {
    position: absolute;
    top: -50%;
    right: -50%;
    transform: rotate(45deg);
    opacity: 0.3;
    z-index: 1;
    border-radius: 50%;
    background: linear-gradient(
      #c2dd67 0%,
      var(--color-image-gradient-green) 15%
    );
    width: 100%;
    height: 100%;
    content: '';
    filter: blur(3rem);
  }
}

.playButton {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition-base);
  color: var(--color-bg-quaternary-cta);

  &:hover,
  &:focus-visible {
    background-color: var(--color-bg-quinary-cta-hover);
    color: var(--color-bg-quaternary-cta-hover);
  }
}

.playIcon,
.pauseIcon {
  width: auto;
  height: spacing(8);
}

.playIcon {
  // Visually align the play icon in the center of the circle
  transform: translateX(3px);
}

.closing {
  position: absolute;
  bottom: 0;
  opacity: 0;
}
