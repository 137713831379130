@import 'app.scss';
.breadcrumbs {
  position: relative;
  z-index: 2;
}

.list,
.listItem {
  display: flex;
  gap: spacing(2);
  align-items: center;

  @include from-breakpoint(md) {
    flex-wrap: wrap;
  }
}

.listItem {
  @include until-breakpoint(md) {
    &:not(.secondToLastPage) {
      @include visually-hidden;
    }
  }
}

.link {
  color: var(--color-text-base);
}

.arrowLeftIcon {
  width: 1rem;
  height: auto;
}

.currentPage {
  text-decoration: none;
  pointer-events: none;
}

.seperator {
  @include until-breakpoint(md) {
    display: none;
  }
}

.arrowLeftIcon,
.backToLabel {
  @include from-breakpoint(md) {
    display: none;
  }
}
