@import 'app.scss';
.section {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(100vh + var(--stories-overlap));
  overflow: hidden;
}

.video {
  display: block;
  transform: scale(1.5); // animates to 1
  width: 100%;
  max-width: 100%;
  height: 100vh;
  overflow: hidden;
  object-fit: cover;
  object-position: 50% 100%;
}

.scroll {
  position: absolute;
  top: calc(100dvh - spacing(1));
  left: 50%;
  transform: translate(-50%, -100%);
  transition: var(--transition-base);
  z-index: 1;
  padding: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  color: var(--color-white);

  &.hidden {
    opacity: 0;
  }
}

@keyframes chevron-animation {
  0% {
    transform: translateY(-50%) scale(0.5);
    opacity: 0;
  }

  15% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }

  85% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateY(25%) scale(1);
    opacity: 0;
  }
}

.chevron {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: center;
  width: 100%;
  height: 100%;
  animation: chevron-animation 3s infinite;
  filter: drop-shadow(0 0 4px rgb(0 0 0 / 0.8));
}
