@import 'app.scss';
.checkbox {
  --checkbox-size: 1.25rem;
  --checkbox-icon-size: 0.75rem;
  --checkbox-gap: #{spacing(3)};

  position: relative;
}

.labelWrapper {
  display: flex;
  gap: var(--checkbox-gap);
  align-items: center;
  transition: var(--transition-base);
  margin-top: spacing(6);
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius-base);
  box-shadow: var(--box-shadow-card);
  color: var(--color-black-300);

  &:hover {
    box-shadow: none;
  }

  .input:focus-visible ~ & {
    @include generic-focus;
  }

  .input:focus-visible ~ &,
  &:hover {
    color: var(--color-fg-default);

    .indicator {
      border-color: var(--color-orange-400);
    }
  }

  .input:checked ~ & {
    color: var(--color-fg-default);

    .indicator {
      border-color: var(--color-orange-500);
    }

    .icon {
      opacity: 1;
    }
  }
}

.label {
  user-select: none;
  hyphens: auto;
}

.indicator {
  position: relative;
  flex-shrink: 0;
  transition: var(--transition-base);
  margin-top: spacing(-1); // align with text
  border: solid 2px var(--color-black);
  border-radius: var(--border-radius-small);
  background-color: var(--color-bg-default);
  width: var(--checkbox-size);
  height: var(--checkbox-size);
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: var(--checkbox-icon-size);
  height: var(--checkbox-icon-size);
  color: var(--color-orange-500);
}
