@import 'app.scss';
.section {
  --item-offset: 24px;

  margin-top: -20vh;
  min-height: 100vh;
  color: var(--color-white);

  @include from-breakpoint(md) {
    margin-top: -75vh; // Negative margin to start the background transition earlier
  }
}

.container {
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding-top: var(--section-vertical-spacing);
  padding-bottom: var(--section-vertical-spacing);
  width: fit-content;
  height: 100vh;
}

.label {
  opacity: 0;
  font-family: var(--font-family-base);
  font-size: 1rem;
  font-weight: 400;
}

.list {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, auto));
  gap: 2px; // design is 1px, but has some render issues
  place-content: center center;
  width: 100%;

  @include from-breakpoint(md) {
    grid-template-columns: repeat(3, minmax(0, auto));
  }

  @include from-breakpoint(xl) {
    grid-template-columns: repeat(4, minmax(0, auto));
  }
}

.item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: var(--border-radius-medium);
  background-color: var(--color-white);
  padding: var(--item-offset);
  aspect-ratio: 1;
  max-width: 300px;

  @include until-breakpoint(md) {
    &:nth-child(n + 3) {
      margin-top: calc(-100% + var(--item-offset));
    }
  }

  @include from-breakpoint(md) {
    padding: 3rem;

    &:nth-child(n + 4) {
      margin-top: calc(-100% + var(--item-offset));
    }
  }

  @include from-breakpoint(lg) {
    padding: 4rem;
  }

  @include from-breakpoint(xl) {
    &:nth-child(4) {
      margin-top: 0;
    }

    &:nth-child(n + 5) {
      margin-top: calc(-100% + var(--item-offset));
    }
  }
}

.imageWrapper {
  display: flex;
  max-width: 9.25rem !important; // 148px design

  @include from-breakpoint(lg) {
    flex-shrink: 0;
  }
}
