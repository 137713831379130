@import 'app.scss';
.staticCta {
  margin-top: spacing(12);

  @include from-breakpoint(md) {
    margin-top: spacing(14);
  }
}

.fixedBar {
  display: flex;
  position: fixed;
  right: 0;
  bottom: var(--section-vertical-spacing);
  left: 0;
  gap: spacing(2);
  align-items: center;
  transition: bottom var(--transition-duration-long)
    var(--transition-timing-base);
  z-index: 5;
  color: var(--color-fg-on-alternative);

  &.fixedBarHidden {
    bottom: spacing(-25);
  }

  @include until-breakpoint(sm) {
    padding: spacing(1);
  }
}

.content {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: spacing(4);
  align-items: center;
  justify-content: space-between;
}

.details {
  display: flex;
  gap: spacing(2);
  align-items: center;
  margin: 0;
  padding: 0;
}

.detail:not(:first-of-type)::before {
  padding-right: spacing(2);
  content: '•';
}

.fixedCta {
  @include until-breakpoint(md) {
    width: 100%;
  }
}

.scrollToTop {
  --scroll-to-top-size: 3.25rem;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-fg-on-default);
  border-radius: calc(var(--scroll-to-top-size) / 2);
  background-color: var(--color-bg-default);
  width: var(--scroll-to-top-size);
  height: var(--scroll-to-top-size);
  color: var(--color-fg-on-default);

  @include from-breakpoint(md) {
    --scroll-to-top-size: 5.5rem;

    border: none;
  }
}

.fixedBarItem {
  @include from-breakpoint(md) {
    border-radius: var(--border-radius-medium);
    background-color: var(--color-bg-alternative);
    padding: spacing(5) spacing(7);
  }
}
