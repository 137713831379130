@import 'app.scss';
.hero {
  display: grid;
  position: relative;
  padding-top: var(--section-vertical-spacing);
  padding-bottom: var(--section-vertical-spacing);
  overflow: hidden;

  @include from-breakpoint(md) {
    margin-top: spacing(-8);
    overflow: visible;
  }

  @include from-breakpoint(lg) {
    margin-top: 0;
  }
}

.contentContainer {
  grid-column: 1/-1;

  @include from-breakpoint(md) {
    grid-row: 1;
  }
}

.title {
  position: relative;
  grid-column: 1/-1;
  z-index: 2;
  margin-top: spacing(18);
  color: var(--color-fg-on-default);

  @include from-breakpoint(md) {
    grid-column: 1 / span 6;
    margin-top: 0;
    margin-bottom: spacing(4);
  }
}

.content {
  grid-column: 1/-1;

  @include from-breakpoint(md) {
    grid-column: 1 / span 5;
  }

  @include from-breakpoint(xl) {
    grid-column: 3 / span 4;
  }
}

.hasChildren {
  margin-bottom: spacing(10);

  @include from-breakpoint(md) {
    margin-bottom: spacing(8);
  }
}

.imageContainer {
  position: relative;
  grid-column: 1/-1;
  order: -1;
  margin-right: auto;
  margin-left: auto;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  overflow: hidden;

  @include from-breakpoint(md) {
    order: unset;
  }
}

.imageWrapper {
  position: relative;
  grid-column: 1/-1;
  order: -1;

  @include from-breakpoint(md) {
    grid-row: 1;
    grid-column: 8/-1;
    order: unset;
  }
}
