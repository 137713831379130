@import 'app.scss';
.wrapper {
  display: flex;
  gap: spacing(2);
  align-items: center;
}

.icon {
  margin-top: spacing(-1); // Align icon with text
}

.value {
  margin-left: 0;
}
