@import 'app.scss';
.main {
  --background-transition-duration: 500ms;
  --stories-overlap: 200px; // used for parallax in hero section

  transition: var(--background-transition-duration);
  background-color: var(--bg-color);
  min-height: 100vh;
  word-break: break-word;
  color: var(--color-black);
  font-family: var(--font-family-heading);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.first {
    --logo-color: var(--color-white);
    --bg-color: var(--color-green-500);
    --header-color: var(--color-black);
  }

  &.second {
    --bg-color: var(--color-gray-100);
    --logo-color: var(--color-purple-500);
    --header-color: var(--color-purple-500);
  }

  &.third {
    --bg-color: var(--color-green-700);
    --logo-color: var(--color-green-500);
    --header-color: var(--color-white);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.125;
    word-break: break-word;
    color: inherit;
  }
}

.header {
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: end;
  z-index: 3;
  padding: spacing(10) spacing(6);
  width: 100%;
  text-align: right;
  line-height: 1.1;
  color: var(--header-color);
  font-family: var(--font-family-base);
  font-weight: 400;
  pointer-events: none;

  @include from-breakpoint(lg) {
    padding: spacing(15) spacing(16);
  }
}

.cta {
  transition: 150ms var(--transition-timing-base);
  opacity: 0;
  pointer-events: none;

  .showHeader & {
    opacity: 1;
    pointer-events: auto;
  }
}

.backgroundRef {
  position: relative;
}
