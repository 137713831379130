@import 'app.scss';
.container,
.title {
  --grid-spacing: 0;

  color: var(--color-fg-on-default);
}

.title,
.text,
.ctaWrapper {
  grid-column: 1/-1;

  @include from-breakpoint(lg) {
    grid-column: 3/10;
  }
}

.text {
  margin-bottom: spacing(8);

  @include from-breakpoint(md) {
    margin-bottom: spacing(12);
  }
}
