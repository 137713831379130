@import 'app.scss';
.input:focus-visible + .label {
  background-color: var(--bg-color-hover);
  color: var(--color-hover);
}

.button {
  @include button(secondary);
}

.label {
  margin-bottom: -4px;
  line-height: 1.7;
}

.fileName,
.error {
  margin: spacing(4) 0 spacing(2);
}

.fileName {
  display: block;
}

.error {
  color: var(--color-error-500);
}
