@import 'app.scss';
.section {
  padding-top: var(--section-vertical-spacing);
  padding-bottom: var(--section-vertical-spacing);
  min-height: 40vh;
  overflow: hidden;
  color: var(--color-white);

  @include from-breakpoint(lg) {
    margin-top: -20vh;
    margin-bottom: -20vh;
    padding-top: 30vh;
    padding-bottom: 30vh;
  }
}

.container {
  display: grid;
  gap: spacing(8);
  align-content: center;

  @include from-breakpoint(lg) {
    grid-template-columns: auto 1fr;
    gap: spacing(14);
  }
}

.label {
  font-family: var(--font-family-base);
  font-size: 1rem;
  font-weight: 400;
}

.item {
  padding: spacing(4) 0;
  font-size: 2rem;

  &:not(:last-child) {
    border-bottom: solid 1px rgb(255 255 255 / 0.2);
  }

  &:first-child {
    margin-top: -0.375em;
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  @include from-breakpoint(md) {
    font-size: 3rem;
  }

  @include from-breakpoint(lg) {
    font-size: 5rem;
  }
}

.imageContainer {
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translate(0, -50%);
  z-index: 1;
  pointer-events: none;
}

.image {
  position: relative;
  border-radius: 50%;
  width: auto;
  height: 50vh;
  aspect-ratio: 1 / 1;
  overflow: hidden;
}

.title {
  position: relative;
  z-index: 2;
}

.itemLabel {
  position: relative;
  transition:
    opacity var(--transition-duration-long) var(--transition-timing-base),
    color var(--transition-duration-long) var(--transition-timing-base);
  text-decoration: none;
  line-height: 1.125;
  color: var(--color-white);
  font-weight: 500;

  .list:hover &,
  .list:focus-within & {
    opacity: 0.09;
  }

  .item:hover &,
  &:focus-visible {
    opacity: 1 !important;
    color: var(--color-green-500);
  }
}
