@import 'app.scss';
.item {
  position: relative;

  &:hover,
  &:focus-within {
    .image {
      transform: scale(1.05);
    }
  }

  @include from-breakpoint(xl) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: spacing(2);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--border-radius-medium);
  background-color: var(--color-bg-primary-brand);
  padding: spacing(10) spacing(6);
  height: 100%;

  // Matching CompanyItem.module.scss
  min-height: 320px;
  text-align: center;

  @include from-breakpoint(md) {
    min-height: 360px;
  }

  @include from-breakpoint(xl) {
    min-height: 400px;
  }
}

.title {
  position: relative;
  margin-top: spacing(3);
  margin-bottom: spacing(6);
  color: var(--color-fg-on-primary-brand);
}

.imageContainer {
  position: relative;
  border-radius: 50%;
  width: 12rem;
  height: 12rem;
  overflow: hidden;
}

.image {
  transition: transform var(--transition-duration-base)
    var(--transition-timing-base);
}

.cta {
  margin-top: auto;
}

.cutoutTopLeft,
.cutoutTopRight,
.cutoutBottomLeft,
.cutoutBottomRight {
  position: absolute;
  border-radius: 50%;
  background-color: var(--color-bg-default);
  width: 0.5rem;
  height: 0.5rem;
}

.cutoutTopLeft,
.cutoutTopRight {
  top: spacing(2);
}

.cutoutTopLeft,
.cutoutBottomLeft {
  left: spacing(8);
}

.cutoutBottomLeft,
.cutoutBottomRight {
  bottom: spacing(2);
}

.cutoutTopRight,
.cutoutBottomRight {
  right: spacing(8);
}
