@import 'app.scss';
.section {
  display: grid;
  align-content: center;
  padding-top: var(--section-vertical-spacing);
  padding-bottom: var(--section-vertical-spacing);
  min-height: 100vh;
  color: var(--color-white);
}

.title {
  line-height: 1.1;
  font-size: 2rem;

  @include from-breakpoint(md) {
    font-size: 3rem;
  }

  @include from-breakpoint(lg) {
    font-size: 5rem;
  }
}

.ctaContainer {
  display: flex;
  flex-flow: row wrap;
  gap: spacing(3);
  margin-top: spacing(8);

  @include from-breakpoint(lg) {
    margin-top: spacing(18);
  }
}
