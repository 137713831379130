@import 'app.scss';
.section {
  display: grid;
  position: relative;
  align-content: start;
  margin-top: -20vh;
  width: 100%;
  color: var(--color-green-700);

  @include from-breakpoint(lg) {
    margin-top: -40vh;
  }
}

.stickyContainer {
  display: grid;
  position: sticky;
  top: 0;
  align-content: center;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.list {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  padding-top: var(--section-vertical-spacing);
  padding-bottom: var(--section-vertical-spacing);
  width: 100%;

  @include from-breakpoint(lg) {
    align-items: center;
  }
}

.item {
  --spacing: #{spacing(8)};

  display: grid;
  flex-shrink: 0;
  gap: spacing(4);
  padding-right: var(--spacing);
  width: 80%;
  max-width: calc(960px + var(--spacing));

  @include from-breakpoint(md) {
    --spacing: #{spacing(12)};
  }

  @include from-breakpoint(lg) {
    --spacing: #{spacing(15)};

    grid-template-columns: auto 1fr;
    gap: spacing(8);
    width: 90%;
  }

  @include from-breakpoint(xl) {
    gap: spacing(14);
    padding-right: spacing(30); // 120px
  }
}

.image {
  display: none;
  border-radius: var(--border-radius-medium);
  width: 100%;
  max-width: 430px;

  &.mobile {
    display: block;

    @include from-breakpoint(lg) {
      display: none;
    }
  }

  @include from-breakpoint(lg) {
    display: block;
    width: 20rem;
  }
}

.content {
  display: flex;
  flex-direction: column;
}

.name,
.job {
  font-family: var(--font-family-base);
  font-size: 1rem;
  font-weight: 400;
}

.text {
  margin-top: auto;
  line-height: 1.125;
  font-size: 1.5rem;

  @include from-breakpoint(md) {
    font-size: 2rem;
  }

  @include from-breakpoint(lg) {
    font-size: 2.25rem;
  }

  @include from-breakpoint(xl) {
    font-size: 2.5rem;
  }
}
