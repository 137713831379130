@import 'app.scss';
.wrapper {
  position: relative;
  margin-top: spacing(6);
  width: 100%;
}

.input {
  @include typography-body;

  outline: none;
  border: 1px solid var(--color-gray-100);
  border-radius: var(--border-radius-small);
  box-shadow: var(--box-shadow-card);
  padding: spacing(3) spacing(6) spacing(2);
  width: 100%;
  min-height: var(--input-height);
  resize: none;

  &::placeholder {
    color: var(--color-gray-200);
  }

  &:disabled {
    background-color: var(--color-gray-300);
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      border-color: var(--color-purple-500);
    }
  }

  &.textarea {
    padding: spacing(6);
  }
}

.label {
  @include typography-body;

  margin-bottom: spacing(2);
}
