@import 'app.scss';
.item {
  position: relative;

  &:hover,
  &:focus-within {
    .image {
      transform: scale(1.05);
    }
  }

  @include from-breakpoint(xl) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: spacing(2);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--border-radius-medium);

  // Color is matching design but is not used anywhere else
  box-shadow: 0 0 0 1px rgb(102 222 128 / 0.1) inset;
  background-color: var(--color-bg-alternative);
  padding: spacing(10) spacing(6);
  height: 100%;

  // To match design
  min-height: 320px;
  text-align: center;

  @include from-breakpoint(sm) {
    box-shadow: none;
  }

  @include from-breakpoint(md) {
    min-height: 360px;
  }

  @include from-breakpoint(xl) {
    min-height: 400px;

    .mirrored & {
      order: 1;
    }
  }
}

.logoContainer {
  position: relative;
  width: 100%;
  height: 1.5rem;
}

.text {
  margin: spacing(10) 0 spacing(5);
  width: 100%;

  &::before {
    display: block;
    opacity: 0.1;
    margin-bottom: spacing(6);
    border-top: 1px solid var(--color-bg-primary-brand);
    content: '';
  }
}

.cta {
  margin-top: auto;
}

.cutoutTopLeft,
.cutoutTopRight,
.cutoutBottomLeft,
.cutoutBottomRight {
  position: absolute;
  border-radius: 50%;
  background-color: var(--color-bg-default);
  width: 0.5rem;
  height: 0.5rem;
}

.cutoutTopLeft,
.cutoutTopRight {
  top: spacing(2);
}

.cutoutTopLeft,
.cutoutBottomLeft {
  left: spacing(8);
}

.cutoutBottomLeft,
.cutoutBottomRight {
  bottom: spacing(2);
}

.cutoutTopRight,
.cutoutBottomRight {
  right: spacing(8);
}

.imageContainer {
  display: none;

  @include from-breakpoint(xl) {
    display: block;
    position: relative;
    border-radius: var(--border-radius-medium);
    overflow: hidden;
  }
}

.image {
  transition: transform var(--transition-duration-base)
    var(--transition-timing-base);
}
