@import 'app.scss';
.button {
  @include button;

  // Disabling order otherwise these variables will be overridden by the button mixin
  /* stylelint-disable-next-line order/order */
  --color: var(--color-fg-on-filter-cta);
  --color-hover: var(--color-fg-on-filter-cta-hover);
  --bg-color: var(--color-bg-filter-cta);
  --border-color: var(--color-fg-on-filter-cta);
  --bg-color-hover: var(--color-bg-filter-cta-hover);
}

.input:focus-visible + .button {
  @include generic-focus;
}

.input:checked + .button {
  --color: var(--color-hover);
  --bg-color: var(--bg-color-hover);

  &:hover,
  &:focus-visible {
    opacity: 0.7;
  }
}
