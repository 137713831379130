@import 'app.scss';
.list {
  display: flex;
  flex-wrap: wrap;
  gap: spacing(4);
  margin-bottom: 0;
}

.narrow {
  max-width: 20rem;
}
