@import 'app.scss';
.filterContainer {
  // Let the filter buttons go to left and right of the screen
  padding-right: 0;
  padding-left: 0;
}

.title {
  padding: 0 var(--horizontal-padding);
}

.form {
  --spacing-top: #{spacing(4)};

  display: grid;
  gap: spacing(2);

  // We need the 3px padding to make the tab focus outline visible, but we don't want it to affect the layout
  // this is caused by the overflow auto on line 12
  margin: -3px 0;
  margin-top: calc(var(--spacing-top) - 3px);
  padding: 3px var(--horizontal-padding);
  overflow: auto;

  @include from-breakpoint(md) {
    --spacing-top: #{spacing(6)};
  }
}

.row {
  display: flex;

  > * {
    flex-shrink: 0;
    padding-right: spacing(2);
  }
}
