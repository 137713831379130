@import 'app.scss';
.list {
  display: flex;
  flex-flow: row nowrap;
  gap: spacing(6);
  padding-top: 30vh;
  width: 100%;
  height: auto;
  min-height: 100vh;
  overflow-x: auto;
  list-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.item {
  flex-shrink: 0;
  width: 90%;
  max-width: 400px;
  color: var(--color-green-700);
}

.image {
  margin-bottom: spacing(4);
  border-radius: var(--border-radius-medium);
}

.text {
  margin-top: spacing(2);
  line-height: 1.125;
  font-size: 1.5rem;
}

.name,
.job {
  font-family: var(--font-family-base);
  font-size: 1rem;
  font-weight: 400;
}

.name {
  margin-top: spacing(10);
}

.job {
  margin-bottom: spacing(2);
}
