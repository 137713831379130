@import 'app.scss';
.wrapper {
  margin-top: spacing(6);
}

.upload {
  border-radius: var(--border-radius-small);
  background: var(--color-white);
  padding-top: spacing(12);
  padding-bottom: spacing(12);
  text-align: center;
}

.helpText {
  display: block;
  margin-top: spacing(2);
  margin-bottom: 0;
  font-style: italic;
}

.label {
  @include typography-body;

  margin-bottom: spacing(2);
}
