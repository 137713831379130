@import 'app.scss';
.canvasContainer {
  position: fixed;
  inset: 0;
  transform-origin: center center;
  z-index: 5;
  color: var(--logo-color);
  pointer-events: none;
}

.canvas {
  pointer-events: none;
}

.button {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: auto;
}

.logoContainer {
  --logo-ratio: 3.38;
  --logo-width: clamp(122px, 30vw, 205px);

  position: absolute;
  pointer-events: auto;

  /* Overwrite style properties set by matter.js */
  @include until-breakpoint(md) {
    transform: translate(-50%, -50%) !important;
    transition: 500ms !important;

    &.center {
      top: 50% !important;
      left: 50% !important;
    }

    &.top {
      top: 60px !important;
      left: calc(spacing(6) + var(--logo-width) / 2) !important;
    }

    &.bottom {
      top: calc(100% - var(--logo-width) / var(--logo-ratio) / 2) !important;
    }
  }
}

.logo {
  transform: scale(2);
  transform-origin: center center;
  transition: transform 500ms;
  width: var(--logo-width);
  height: auto;

  &.scrolled {
    transform: scale(1);
  }

  /* Overwrite style properties set by matter.js */
  @include until-breakpoint(md) {
    cursor: default !important;
  }
}
