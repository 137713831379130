@import 'app.scss';
.card {
  position: relative;
  transition-duration: var(--transition-duration-base);
  transition-property: background-color;
  transition-timing-function: var(--transition-duration-timing);
  border-radius: var(--border-radius-xl);
  background-color: var(--color-bg-alternative);
  padding: spacing(13) spacing(9) spacing(12);
  text-align: center;
  color: var(--color-fg-on-alternative);

  @include from-breakpoint(sm) {
    text-align: left;
  }

  @include from-breakpoint(md) {
    padding: spacing(14) spacing(20) spacing(14) spacing(14);
  }

  @include from-breakpoint(lg) {
    display: flex;
    gap: spacing(8);
    align-items: center;
    justify-content: space-between;
  }
}

.content {
  margin-bottom: spacing(15);

  @include from-breakpoint(lg) {
    margin-bottom: 0;
  }
}

.title {
  margin-bottom: spacing(8);

  @include from-breakpoint(md) {
    margin-bottom: spacing(7);
  }
}

.description {
  margin-bottom: spacing(8);
  border-top: 1px solid var(--color-gray-100);
  padding-top: spacing(5);
  max-width: var(--max-text-width);

  @include from-breakpoint(md) {
    margin-bottom: spacing(10);
  }

  @include from-breakpoint(lg) {
    border-top: none;
    padding-top: 0;
  }
}

.specifications {
  justify-content: center;

  @include from-breakpoint(sm) {
    justify-content: flex-start;
  }
}

.cta {
  flex-shrink: 0;
}

.card:hover {
  @include from-breakpoint(lg) {
    background-color: var(--color-bg-primary-brand);
    color: var(--color-fg-on-primary-brand);

    .title {
      color: var(--color-fg-on-primary-brand);
    }

    .cta {
      --bg-color: var(--color-bg-secondary-cta-hover);
    }
  }
}
